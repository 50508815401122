import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { Lesson, Training } from '../../models';
import { Program } from '@shared/models';

export const loadTraining = createAction('[Preview] Load Training', props<{ key: string }>());

export const loadTrainingSuccess = createAction(
  '[Preview] Load Training success',
  props<{ training: Training }>()
);

export const loadTrainingFail = createAction(
  '[Preview] Load Training fail',
  props<{ error: HttpErrorResponse }>()
);

export const loadLesson = createAction('[Preview] Load Lesson', props<{ id: string }>());

export const loadLessonSuccess = createAction(
  '[Preview] Load Lesson success',
  props<{ lesson: Lesson }>()
);

export const loadLessonFail = createAction(
  '[Preview] Load Lesson fail',
  props<{ error: HttpErrorResponse }>()
);

export const start = createAction('[Preview] Start');

export const openProgram = createAction(
  '[Preview] Open program',
  props<{ program: Program | Partial<Program> }>()
);

export const next = createAction('[Preview] Next program', props<{ id: string }>());

export const prev = createAction('[Preview] Prev program', props<{ id: string }>());

export const backToLesson = createAction('[Preview] Bac to lesson', props<{ taskId: string }>());

export const toggleSidebar = createAction('[Preview] Toggle sidebar');

export const setInitialState = createAction('[Learning Training] Set initial state');
